import { createGlobalStyle } from "styled-components";
import variables from "./variables";
import TransitionStyles from "./TransitionStyles";

const GlobalStyle = createGlobalStyle`
  ${variables};

  @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
  
  html {
    box-sizing: border-box;
    width: 100%;
    scroll-behavior: smooth;
  }

  body {
    background-color: var(--white);
    font-family: 'Work Sans', sans-serif;
  }

  a {
    color: inherit;
    transition: var(--transition);

    &:hover,
    &:focus {
      color: var(--secondary);
    }
  }

  .text-secondary {
    color: var(--secondary) !important;
  }

  .custom-shape-divider-bottom-1657544194 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1657544194 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 99px;
}

.custom-shape-divider-bottom-1657544194 .shape-fill {
    fill: #b7223f;
}
  
  :focus {
    outline: none;
  }

  ${TransitionStyles}
`;

export default GlobalStyle;
