import { css } from "styled-components";

const TransitionStyles = css`
  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: all 3s ease-in 1s;
  }
`;

export default TransitionStyles;
