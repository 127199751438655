import { css } from "styled-components";

const variables = css`
  :root {
    --primary: #000000;
    --secondary: #b7223f;
    --dark: #0f172a;
    --black: #222831;
    --black-90: #25221f;
    --black-80: #575756;
    --white: #ffffff;
    --grey: #adacac;
    --green: #28df99;
    --blue: #19b2ff;
    --easing: cubic-bezier(0.6, 0.04, 0.3, 1);
    --transition: all 0.25s cubic-bezier(0.6, 0.4, 0.3, 1);
  }
`;

export default variables;
