import React from "react";
import { Layout, Hero, About, Services, Fleet, Contact } from "@components";

const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Hero />
      <About />
      <Services />
      <Fleet />
      <Contact />
    </Layout>
  );
};

export default IndexPage;
