import React from "react";
import styled from "styled-components";
import tw from "tailwind-styled-components/dist/tailwind";
import background from "../../images/contact-image.jpg";

const SectionContainer = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${background});
  background-repeat: no-repeat;
  background-position: center;
  min-height: 300px;

  @media screen and (min-width: 768px) {
    background-size: 100%;
  }
`;

const StyledSectionContainer = tw(SectionContainer)`
  mx-auto
  py-20
  max-w-8xl
  px-10
  lg:px-32
`;

const Contact = () => {
  return (
    <section id="contact-us">
      <StyledSectionContainer>
        <h1 className="font-light text-white tracking-normal uppercase text-3xl">
          Contact
          <span className="ml-2 font-semibold">Us</span>
        </h1>
        <div className="grid grid-cols-3 mt-10 gap-10">
          <div className="col-span-3 lg:col-span-1">
            <p className="text-white font-medium mb-3">Address:</p>
            <span className="text-gray-100 font-light">
              Sitio Maislap, Barangay San Isidro, Rodriguez Rizal
            </span>
          </div>
          <div className="col-span-3 lg:col-span-1">
            <p className="text-white font-medium mb-3">Contact No.:</p>
            <ul className="text-white font-light">
              <li>(+63) 967 701 3460</li>
              <li>(+63) 930 307 1434</li>
              <li>(+63) 969 222 1300</li>
            </ul>
          </div>
          <div className="col-span-3 lg:col-span-1">
            <p className="text-white font-medium mb-3">Email:</p>
            <ul className="text-white font-light">
              <li>
                <a href="mailto:rd.manueljr@yahoo.com">rd.manueljr@yahoo.com</a>
              </li>
              <li>
                <a href="mailto:keandasco@yahoo.com">keandasco@yahoo.com</a>
              </li>
              <li>
                <a href="mailto:jayvillanueva_16@yahoo.com">
                  jayvillanueva_16@yahoo.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </StyledSectionContainer>
    </section>
  );
};

export default Contact;
