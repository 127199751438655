import React from "react";
import { PropTypes } from "prop-types";
import { Nav, Head } from "@components";
import { GlobalStyle } from "@styles";
import tw from "tailwind-styled-components/dist/tailwind";

const StyledDiv = tw.div`
  flex
  flex-col
  min-h-screen
`;

const Layout = ({ children, location }) => {
  return (
    <>
      <Head />
      <GlobalStyle />
      <StyledDiv>
        <Nav location={location} />
        <main className="max-w-7xl xl:max-w-full">{children}</main>
      </StyledDiv>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Layout;
