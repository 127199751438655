import React, { useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { srConfig } from "@config";
import sr from "@utils/sr";
import styled from "styled-components";
import tw from "tailwind-styled-components/dist/tailwind";

const SectionContainer = styled.div`
  min-height: 500px;
  background: #b7223f;
`;

const StyledSectionContainer = tw(SectionContainer)`
  mx-auto
  w-full
`;

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/services/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        nodes {
          id
          body
          frontmatter {
            title
            order
            image_alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const revealContainer = useRef(null);

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <section id="services">
      <StyledSectionContainer>
        <div className="max-w-2xl px-5 mx-auto pt-5 pb-24 space-y-10">
          <h1 className="font-light text-white tracking-normal text-center uppercase text-3xl">
            Our
            <span className="ml-2 font-semibold">Services</span>
          </h1>
          <div className="space-y-12" ref={revealContainer}>
            {data.allMdx.nodes.map((item, index) => {
              const image = getImage(item.frontmatter.image);

              return (
                <div key={index} className="md:text-justify">
                  <h2 className="text-white mb-2 text-lg uppercase font-medium tracking-wider">
                    {item.frontmatter.title}
                  </h2>
                  <div className="text-white text-sm tracking-wide">
                    <MDXRenderer>{item.body}</MDXRenderer>
                  </div>
                  <GatsbyImage
                    image={image}
                    alt={item.frontmatter.image_alt}
                    className="w-full mt-5 h-48 object-cover rounded-lg"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </StyledSectionContainer>
    </section>
  );
};

export default Services;
