module.exports = {
  MenuItems: [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "About Us",
      url: "/#about-us",
    },
    {
      name: "Services",
      url: "/#services",
    },
    {
      name: "Fleet",
      url: "/#fleet",
    },
    {
      name: "Contact Us",
      url: "/#contact-us",
    },
  ],

  srConfig: (delay = 200, viewFactor = 0.25) => ({
    origin: "bottom",
    distance: "40px",
    duration: 1500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: "cubic-bezier(0.645, 0.045, 0.355, 1)",
    mobile: true,
    reset: false,
    useDelay: "always",
    viewFactor,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
