import React, { useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { srConfig } from "@config";
import sr from "@utils/sr";
import styled from "styled-components";
import tw from "tailwind-styled-components/dist/tailwind";

const SectionContainer = styled.div`
  min-height: 500px;
`;

const StyledSectionContainer = tw(SectionContainer)`
  py-20
  max-w-8xl
  mx-auto 
  px-10 
  lg:px-32
`;

const Fleet = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/fleet/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        nodes {
          id
          body
          frontmatter {
            title
            order
            image_alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const revealContainer = useRef(null);

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <section id="fleet" ref={revealContainer}>
      <StyledSectionContainer>
        <h1 className="font-light tracking-normal uppercase text-3xl">
          Our
          <span className="ml-2 font-semibold text-secondary">Fleet</span>
        </h1>
        <div className="carousel carousel-center max-w-7xl mt-12 p-4 space-x-4 rounded-box">
          {data.allMdx.nodes.map((item, index) => {
            const image = getImage(item.frontmatter.image);

            return (
              <div key={index} className="carousel-item">
                <div className="card w-52 shadow">
                  <figure>
                    <GatsbyImage
                      image={image}
                      alt={item.frontmatter.image_alt}
                      className="h-44"
                    />
                  </figure>
                  {item.frontmatter.title && (
                    <div className="absolute bottom-0 p-2 bg-black/70 w-full">
                      <h2 className="text-white text-sm tracking-wider uppercase text-center font-medium">
                        {item.frontmatter.title}
                      </h2>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </StyledSectionContainer>
    </section>
  );
};

export default Fleet;
