import React from "react";
import { PropTypes } from "prop-types";
import { IoMenu, IoDiamondOutline } from "react-icons/io5";
import { AiOutlineStar } from "react-icons/ai";

const Icon = ({ name, classes }) => {
  switch (name) {
    case "Menu":
      return <IoMenu className={classes} />;
    case "Diamond":
      return <IoDiamondOutline className={classes} />;
    case "Star":
      return <AiOutlineStar className={classes} />;
    default:
      return null;
  }
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

export default Icon;
