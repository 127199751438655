import React, { useState, useEffect } from "react";
import background from "../../images/hero-image.jpg";
import tw from "tailwind-styled-components/dist/tailwind";
import styled from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const HeroBackground = styled.section`
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${background});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  @media screen and (min-width: 768px) {
    background-size: 100%;
  }
`;

const StyledHeroSection = tw(HeroBackground)`
  p-0
  flex
  flex-column
  min-h-screen
  w-full
`;

const StyledCenterDiv = tw.div`
  flex
  items-center
  px-12
  md:px-32
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 1000);
    return () => clearTimeout(timeout);
  }, []);

  const first = (
    <p className="text-4xl md:text-5xl mb-16 mt-8 text-white uppercase tracking-wider font-semibold">
      You call it, <br />
      we <span className="text-secondary">haul</span> it.
    </p>
  );

  const second = (
    <h1 className="text-2xl uppercase tracking-wider text-gray-200">
      RD Manuel Jr
    </h1>
  );

  const third = (
    <p className="text-gray-400 text-lg tracking-widest uppercase">
      Hauling Services
    </p>
  );

  const items = [first, second, third];
  const delays = [300, 800, 1200];

  return (
    <StyledHeroSection>
      <StyledCenterDiv>
        <div>
          <TransitionGroup component={null}>
            {isMounted &&
              items.map((item, index) => (
                <CSSTransition key={index} timeout={200} classNames="fade">
                  <div style={{ transitionDelay: `${delays[index]}ms` }}>
                    {item}
                  </div>
                </CSSTransition>
              ))}
          </TransitionGroup>
        </div>
      </StyledCenterDiv>
    </StyledHeroSection>
  );
};

export default Hero;
