import React, { Fragment, useEffect, useState } from "react";
import { MenuItems } from "@config";
import { Icon } from "@components/icons";
import { Popover, Transition } from "@headlessui/react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import tw from "tailwind-styled-components/dist/tailwind";
import styled, { css } from "styled-components";

const Header = styled.header`
  @media (prefers-reduced-motion: no-preference) {
    ${(props) =>
      props.scrollState === "down" &&
      css`
        height: 100px;
        transform: translateY(0px);
        background-color: var(--white);
        box-shadow: 0 5px 30px -15px var(--grey);
      `}
  }
`;

const StyledHeader = tw(Header)`
  fixed
  w-full
  max-w-full
  z-10
`;

const StyledNav = tw.nav`
  max-w-7xl
  xl:max-w-full
  mx-auto
  py-4
  px-10
  lg:px-20
  lg:py-2
`;

const StyledDivFlex = tw.div`
  flex
  justify-between
  items-center
`;

const StyledButtonContainer = tw.div`
  flex
  items-center
  -mr-2
  lg:hidden
`;

const StyledMenu = tw.div`
  flex
  justify-start 
  items-center 
  space-x-6
`;

const StyledMenuItem = tw.p`
  ${(props) =>
    props.$scrollState === "down" ? `text-gray-500` : `text-gray-100`}
  text-sm
`;

const StyledPanelBody = tw.div`
  rounded-lg
  shadow-md 
  bg-gray-900/60
  backdrop-blur-md
  overflow-hidden
`;

const StyledPanelContainer = tw.div`
  px-5 
  pt-4 
  pb-2
  text-white
`;

const StyledPanelMenu = tw.div`
  p-4
  flex
  flex-col
  space-y-2
`;

const HiddenContainer = tw.div`
  hidden
  lg:block
`;

const menuButtonClasses = `
  p-1
  rounded-md
  inline-flex 
  items-center 
  justify-center 
  hover:bg-gray-200
`;

const menuIconClasses = `
  w-6
  h-full
  text-gray-300
`;

const popoverPanelClasses = `
  p-2
  z-10
  top-0 
  inset-x-0 
  absolute 
  lg:hidden
`;

const Nav = ({ location }) => {
  const [scrollState, setScrollState] = useState("up");

  useEffect(() => {
    const listener = document.addEventListener("scroll", (e) => {
      const scrolled = document.scrollingElement.scrollTop;

      if (scrolled >= 100) {
        if (scrollState !== "down") setScrollState("down");
      } else {
        if (scrollState !== "up") setScrollState("up");
      }
    });

    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [scrollState]);

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const sectionId = hash.substring(1);
      const yOffset = sectionId === "services" ? -50 : -140;
      const element = document.getElementById(sectionId);
      const yCoordinate =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: yCoordinate });
    }
  }, [location.hash]);

  return (
    <StyledHeader scrollState={scrollState}>
      <StyledNav>
        <Popover>
          <StyledDivFlex>
            <div className="w-16 md:w-20">
              <StaticImage alt="RD Manuel Jr Logo" src="../images/logo.png" />
            </div>
            <StyledButtonContainer>
              <Popover.Button className={menuButtonClasses}>
                <Icon name="Menu" classes={menuIconClasses} />
              </Popover.Button>
            </StyledButtonContainer>
            <HiddenContainer>
              <StyledMenu>
                {MenuItems.map(({ name, url }, index) => (
                  <StyledMenuItem key={index} $scrollState={scrollState}>
                    <Link to={url}>{name}</Link>
                  </StyledMenuItem>
                ))}
              </StyledMenu>
            </HiddenContainer>
          </StyledDivFlex>
          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel className={popoverPanelClasses}>
              <StyledPanelBody>
                <StyledPanelContainer>
                  <p>RD Manuel Jr</p>
                  <StyledPanelMenu>
                    {MenuItems.map(({ name, url }, index) => (
                      <StyledMenuItem href={url} key={index}>
                        <Link to={url}>{name}</Link>
                      </StyledMenuItem>
                    ))}
                  </StyledPanelMenu>
                </StyledPanelContainer>
              </StyledPanelBody>
            </Popover.Panel>
          </Transition>
        </Popover>
      </StyledNav>
    </StyledHeader>
  );
};

export default Nav;
