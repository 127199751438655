import React, { useRef, useEffect } from "react";
import { srConfig } from "@config";
import { Icon } from "@components/icons";
import sr from "@utils/sr";
import styled from "styled-components";
import tw from "tailwind-styled-components/dist/tailwind";

const SectionContainer = styled.div`
  min-height: 900px;
`;

const StyledSectionContainer = tw(SectionContainer)`
  py-28
  max-w-4xl
  mx-auto 
  px-10 
  lg:px-32
`;

const About = () => {
  const revealContainer = useRef(null);

  const items = [
    {
      name: "Mission",
      description:
        "To build long-term relationship with our customers and clients and provide exceptional services",
      icon: "Diamond",
    },
    {
      name: "Vision",
      description:
        "To provide quality services that exceeds the expectations of our clients",
      icon: "Diamond",
    },
  ];

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <section id="about-us" ref={revealContainer}>
      <StyledSectionContainer>
        <h1 className="font-light tracking-normal uppercase text-3xl">
          About
          <span className="ml-2 font-semibold text-secondary">Us</span>
        </h1>
        <div className="text-gray-400 tracking-wide mt-10 md:text-justify space-y-8">
          <p>
            <span className="text-gray-600">RD Manuel Jr Hauling Services</span>{" "}
            was established in year 2000. We exist to help establishments
            maintain cleanliness and orderliness in respective areas where
            trash/scrap/waste materials are being kept.
          </p>
          <p>
            RD Manuel Jr Hauling Services is duly organized and existing by the
            virtue of the laws of the Republic of the Philippines, and duly
            registered by the Department of Trade and Industry permitted by the
            Department of Environment and Natural Resources to do business as
            Transporter for Nonhazardous Waste Management in accordance to RA
            9003.
          </p>
        </div>
        <div className="mt-16 space-y-8 px-8 pb-20 tracking-wide">
          {items.map((item, index) => (
            <div key={index} className="grid grid-cols-12">
              <div className="col-span-2 my-auto">
                <Icon name={item.icon} classes="text-3xl text-secondary" />
              </div>
              <div className="col-span-10">
                <p className="text-lg text-gray-600 uppercase tracking-widest font-medium mb-2">
                  {item.name}
                </p>
                <span className="text-gray-400">{item.description}</span>
              </div>
            </div>
          ))}
        </div>
      </StyledSectionContainer>
      <div className="custom-shape-divider-bottom-1657544194">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M649.97 0L599.91 54.12 550.03 0 0 0 0 120 1200 120 1200 0 649.97 0z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default About;
